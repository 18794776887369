import { DocumentNode, print } from 'graphql'
import { fetchData, FetchHeaders } from './fetchData'

export type UnknownObject = Record<string, unknown>

export type GqlRequestArgs<TVariables> = {
  query: DocumentNode
  variables: TVariables
  timeout?: number
}

/** Our own abstraction for making direct graphql styled post requests.
 * NOTE: If used in a react component, it needs to be used inside of an effect hook to avoid Next SSG 'window undefined' errors. */
export const graphQLRequest = async <TData extends UnknownObject, TVariables extends UnknownObject>({
  query,
  variables,
  timeout,
}: GqlRequestArgs<TVariables>) => {
  const sessionId = window.__FAML_FORMS_CONFIG__.SESSION_ID
  const locale = window.__FAML_FORMS_CONFIG__.LOCALE
  const headers: FetchHeaders = {
    'content-type': 'application/json;charset=utf-8',
  }

  // If we have a session ID available, add it to the headers for BE to use (in error tracking and datadog)
  if (sessionId) {
    headers['FAML-FrontendSessionId'] = sessionId
  }

  if (locale) {
    headers['FAML-Culture'] = locale
  }

  const stringifiedQuery = print(query)

  // Once in a blue 🌙 we get people adding zero width characters into the input field and confuses everyone.
  // This middleware will remove them from the variables before sending them to the server.
  const stripedZeroWidthCharactersVariables = JSON.parse(
    JSON.stringify(variables).replace(/[\u200B-\u200D\uFEFF\u200E\u200F]/g, '')
  )

  const operationName = getOperationName(stringifiedQuery)
  const body = JSON.stringify({
    operationName,
    query: stringifiedQuery,
    variables: stripedZeroWidthCharactersVariables,
  })

  const data = await fetchData<{ data: TData; errors: { message: string }[] }>({
    url: window.__FAML_FORMS_CONFIG__.GQL_API_URL,
    method: 'POST',
    headers,
    body,
    timeout,
  })

  return data
}

const getOperationName = (query: string) => {
  const ENGLISH_PASCAL_CASE_REGEX = /([A-Z][a-z0-9]+)+/
  const matches = query.match(ENGLISH_PASCAL_CASE_REGEX)

  const operationName = matches ? matches[0] : ''

  return operationName
}
